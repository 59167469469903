<template>
  <div class="center">
    <el-form ref="hospital" :model="hospital" :rules="rules">
      <div class="flex-wrap">
        <div class="item" v-for="(item, index) in basicInfo" :key="index">
          <el-form-item :label="item.label" :prop="item.field">
            <el-input :readonly="hospital.id ? true : false" :value="hospital[item.field]" :placeholder="item.label" @input="handleUpdateHospital($event, item.field)"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <el-button class="ml-2 center" type="primary" @click="handleCreateHospital('hospital')">下一步</el-button>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  data () {
    return {
      rules: {
        name: [
          { required: true, message: '请输入诊所名称', trigger: 'blur' },
        ],
        address: [
          { required: true, message: '请输入地址', trigger: 'blur' }
        ],
        doctor_name: [
          { required: true, message: '请输入法定代表人', trigger: 'blur' }
        ],
        telephone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
        //   { pattern: /^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9])[0-9]{8}$/, message: '手机号格式不对', trigger: 'blur' }
        ],
        credit_code: [
          { required: true, message: '请填写统一社会信用代码', trigger: 'blur' }
        ],
        license: [
          { required: true, message: '请填写医疗机构执业许可证登记号', trigger: 'blur' }
        ],
      },
    }
  },

  computed: {
    ...mapState('register', ['hospital', 'basicInfo']),
  },

  methods: {
    ...mapMutations('register', ['updateHospital', 'updateCurrentStep']),
    ...mapActions('register', ['registerHospital']),
    handleUpdateHospital (value, field) {
      this.updateHospital({ field: field, value: value })
    },
    async handleCreateHospital (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (!this.hospital.id) {
            await this.registerHospital()
          } else {
            this.updateCurrentStep(1)
          }
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.item {
  width: 48%;
  label {
    margin-bottom: 0px;
  }
}
.center {
  text-align: center;
}
</style>