<template>
  <div class="vertical-layout">
    <!-- Start Containerbar -->
    <div id="containerbar" class=" authenticate-bg">
      <!-- Start Container -->
      <div class="container">
        <!-- Start row -->
        <div class="row no-gutters align-items-center justify-content-center">
          <!-- Start col -->
          <div class="col-md-12 col-lg-10">
            <!-- Start Auth Box -->
            <div class="auth-box-right">
              <div class="card mt-3">
                <div class="card-body">
                  <form>
                    <h4 class="text-primary my-4 align-center ">诊所注册</h4>
                    <el-steps :active="currentStep">
                      <el-step title="基本信息" icon="el-icon-edit"></el-step>
                      <el-step title="上传凭证" icon="el-icon-upload"></el-step>
                      <el-step title="注册完成" icon="el-icon-success"></el-step>
                    </el-steps>
                    <RegisterBasic v-if="currentStep ==0" />
                    <RegisterUpload v-if="currentStep ==1" />
                    <div v-if="currentStep != 0 && currentStep != 1" class="d-flex register-success">
                      <img style="width: 4rem;" src="@/assets/images/register_success.svg">
                      <div>您已成功申请注册诊所，请耐心等候管理员审核</div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- End Auth Box -->
          </div>
          <!-- End col -->
        </div>
        <!-- End row -->
      </div>
      <!-- End Container -->
    </div>
    <!-- End Containerbar -->
  </div>
</template>
<script>
import RegisterBasic from '../../components/RegisterBasic'
import RegisterUpload from '../../components/RegisterUpload'
import { mapState } from 'vuex'
export default {
  name: 'register',

  data () {
    return {
      
    }
  },

  components: { RegisterBasic, RegisterUpload },

  computed: {
    ...mapState('register', ['currentStep'])
  },

}
</script>

<style scoped>
label {
  margin-bottom: 0px;
}
.align-center {
  text-align: center;
}
.register-success {
  align-items: center;
  font-size: 16px;
  width: 50%;
  margin: 0 auto;
  margin-top: 1rem;
  color: black;
}
</style>