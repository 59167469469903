<template>
  <div>
    <div class="mt-2">
      <span class="title"><span style="color:red">*</span>上传营业执照</span>
      <el-upload class="upload-demo center" action="#" :http-request="uploadLicenseFiles" drag multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <div class="d-flex flex-wrap">
        <template v-for="(image, index) in hospital.licenseFullURLFiles">
          <div :key="index">
            <el-image style="width:200px; height200px; margin:.6rem" :preview-src-list="hospital.licenseFullURLFiles" :src="image" fit="cover"></el-image>
          </div>
        </template>
      </div>
    </div>
    <div class="mt-2">
      <span class="title"><span style="color:red">*</span>上传医疗机构职业许可证</span>
      <el-upload class="upload-demo center" action="#" :http-request="uploadPermitFiles" drag multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <div class="d-flex">
        <template v-for="(image, index) in hospital.permitFullURLFiles">
          <div :key="index">
            <el-image style="width:200px; height200px; margin:.6rem" :preview-src-list="hospital.permitFullURLFiles" :src="image" fit="cover"></el-image>
          </div>
        </template>
      </div>
    </div>
    <div class="center">
      <el-button @click="updateCurrentStep(-1)">上一步</el-button>
      <el-button class="ml-2" type="primary" @click="handleCreateHospital">注册</el-button>
    </div>
  </div>
</template>
<script>
import API from '@/utils/api'
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapState('register', ['hospital'])
  },
  methods: {
    ...mapMutations('register', ['updateCurrentStep']),
    ...mapActions('register', ['updateHospital']),
    async uploadLicenseFiles (files) {
      if (this.hospital.licenseFiles.length > 2) {
        this.$message('只能上传三张以内')
        return
      }
      let file = files.file
      let fileName = file.name
      let idx = fileName.lastIndexOf(".")
      if (idx != -1) {
        let ext = fileName.substr(idx + 1).toUpperCase()
        ext = ext.toLowerCase()
        if (ext === 'png' || ext === 'jpg' || ext === 'jpeg') {
          let res = await API.uploadHospitalFiles({ id: this.hospital.id, file: file, fileType: ext })
          if (res.code === 1) {
            this.hospital.licenseFullURLFiles.push(res.result)
            this.hospital.licenseFiles.push(res.path)
          }
        } else {
          this.$message({
            message: '仅支持.png, .jpg, .jpeg图片格式',
            type: 'warning'
          });
        }
      }
    },
    async uploadPermitFiles (files) {
      if (this.hospital.permitFiles.length > 2) {
        this.$message('只能上传三张以内')
        return
      }
      let file = files.file
      let fileName = file.name
      let idx = fileName.lastIndexOf(".")
      if (idx != -1) {
        let ext = fileName.substr(idx + 1).toUpperCase()
        ext = ext.toLowerCase()
        if (ext === 'png' || ext === 'jpg' || ext === 'jpeg') {
          let res = await API.uploadHospitalFiles({ id: this.hospital.id, file: file, fileType: ext })
          if (res.code === 1) {
            this.hospital.permitFullURLFiles.push(res.result)
            this.hospital.permitFiles.push(res.path)
          }
        } else {
          this.$message({
            message: '仅支持.png, .jpg, .jpeg图片格式',
            type: 'warning'
          });
        }
      }
    },
    async handleCreateHospital () {
      if (!this.hospital.licenseFiles.length || !this.hospital.permitFiles.length) {
        this.$message('请上传相关凭证')
        return
      }
      await this.updateHospital()
    }
  }
}
</script>
<style scoped>
.title {
  font-size: 14px;
  color: #606266;
}
.center {
  text-align: center;
}
</style>